<script>
export default {
  name: 'WhiteFrame',
  props: {
    dotsPosition: {
      type: String,
      validator: v => ['top', 'bottom'].includes(v),
      default: 'top'
    }
  },
  render(createElement) {
    return createElement(
      'div',
      {
        class: {
          'white-frame': true,
          [`white-frame--dots-${this.dotsPosition}`]: this.dotsPosition
        }
      },
      this.$slots.default
    )
  }
}
</script>

<style scoped lang="sass">
.white-frame
  +media((padding: (0: rem(24), 768: rem(40) rem(50) rem(50) rem(40))))
  background: $white
  border-radius: rem(30)
  position: relative
  &:after
    content: ""
    position: absolute
    pointer-events: none
  &--dots-top
    &:after
      content: url(~@/assets/img/white-frame--top.png)
      top: 0
      transform: translate(-30%, -94%)
      right: 0

      @media screen and (max-width: 1023px)
        display: none

  &--dots-bottom
    &:after
      content: url(~@/assets/img/white-frame--bottom.png)
      left: 0
      bottom: 0
      transform: translateY(88%)
</style>
