<template>
  <div id="__app" ref="app" class="app" :class="{ 'app--menu-hidden': !isAsideShowed }">
    <!-- <div class="app-wrapper"> -->
    <notifications group="formNotifications" />
    <main class="app-main">
      <span ref="menu-observer" class="menu-observer" />
      <menu-toggle
        v-if="!isAsideShowed && $root.width <= APP_MIN_WIDTH + diff"
        :is-menu-open="isMobileMenuOpen"
        @toggleMenu="toggleMenu"
      />
      <router-view />
      <app-footer />
    </main>
    <transition name="slide">
      <app-aside v-if="isAsideShowed" />
    </transition>
    <transition name="slide">
      <app-mobile-menu v-if="isMobileMenuOpen" />
    </transition>
    <div
      v-if="isMobileMenuOpen"
      key="backdrop"
      class="backdrop"
      @click="isMobileMenuOpen = false"
    />
    <!-- </div> -->
  </div>
</template>

<script>
import AppFooter from '@/components/dump/AppFooter'
import AppAside from '@/components/dump/AppAside'
import MenuToggle from '@/components/Smart/MenuToggle'
import AppMobileMenu from '@/components/dump/AppMobileMenu'

export default {
  name: 'App',
  components: {
    AppMobileMenu,
    MenuToggle,
    AppAside,
    AppFooter
  },

  data: () => ({
    resizeObserver: null,
    mobileMenuObserver: null,
    isAsideShowed: true,
    isMobileMenuOpen: false,
    diff: 20,
    APP_MIN_WIDTH: 1200
  }),

  watch: {
    $route() {
      if (this.isMobileMenuOpen) {
        this.toggleMenu()
      }
    }
  },

  mounted() {
    this.initResizeObserver()
    this.initMobileMenuObserver()
  },

  beforeDestroy() {
    this.disconnectObservers()
  },

  methods: {
    disconnectObservers() {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
      this.mobileMenuObserver.disconnect()
      this.mobileMenuObserver = null
    },

    initMobileMenuObserver() {
      this.mobileMenuObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (!entry.isIntersecting) {
            if (
              this.isMobileMenuOpen &&
              !this.isAsideShowed &&
              this.$refs.app.getBoundingClientRect().width > 1024
            )
              this.toggleMenu()
          }
        })
      }, {})
      this.mobileMenuObserver.observe(this.$refs['menu-observer'])
    },

    initResizeObserver() {
      this.resizeObserver = new ResizeObserver(() => {
        this.$refs.app.getBoundingClientRect().width > this.APP_MIN_WIDTH
          ? this.showAppAside()
          : this.hideAppAside()
      })

      this.resizeObserver.observe(this.$refs.app)
    },

    hideAppAside() {
      if (this.isAsideShowed) {
        this.isAsideShowed = false
      }
    },

    showAppAside() {
      if (!this.isAsideShowed) {
        this.isAsideShowed = true
      }

      if (this.isMobileMenuOpen) {
        this.isMobileMenuOpen = false
      }
    },

    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    }
  }
}
</script>

<style lang="sass">
.menu-observer
  pointer-events: none
div
  box-sizing: border-box
body
  margin: 0
  background-image: url(~@/assets/img/bg.jpg)
  background-size: cover
  background-attachment: fixed
  background-position: right
.app
  font-family: $main-font, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: $black
  min-height: 100vh
  display: grid
  +media((grid-template-columns: (0: 1fr rem(420), 1200: 1fr rem(340), 1400: 1fr rem(420))))
  padding: rem(20)
  align-items: flex-start
  box-sizing: border-box
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
  input[type=number]
    -moz-appearance: textfield
  .notification
    &-content, &-title
      font-family: $main-font
      font-size: rem(14)
    &-content
      a
        color: $white

  &--menu-hidden
    grid-template-columns: 1fr
    +media((padding: (0: rem(5), 768: rem(10))))

.app-main
  background-color: #F1F1F1
  background-image: url(~@/assets/img/main-bg.png)
  background-position-x: center
  background-size: cover
  border-radius: rem(50)
  transition: .5s
  overflow: hidden

  display: grid
  grid-template-rows: 1fr auto

  .app--menu-hidden &
    +media((border-radius: (0: rem(12), 768: rem(25))))

.slide-enter-active, .slide-leave-active
  transition: .5s

.slide-enter, .slide-leave-to
  opacity: 0
  transform: translateX(100%)

.fade-enter-active, .fade-leave-active
  transition-property: opacity
  transition-duration: 0.3s

.fade-enter-active
  transition-delay: 0.1s

.fade-enter, .fade-leave-active
  opacity: 0

.backdrop
  position: fixed
  top: 0
  left: 0
  height: 100%
  width: 100%
  background: rgba($black, .7)
</style>
