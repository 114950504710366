<template>
  <section class="contacts-page">
    <div class="contacts-page__introduction">
      <div class="contacts-page__introduction-content">
        <common-title class="title"> Contacts</common-title>
        <common-text class="description common-text--contacts">
          <b>Registration №:</b> 202016312D<br />
          <b>Company Name:</b> GIPNETIX PTE. LTD.<br />
          <b>Registration Type:</b> Exempt Private Company Limited by Shares<br />

          <b>Company Address:</b>
          <a href="https://goo.gl/maps/8NBmSxcXNW7aePEX8" target="_blank">
            8 MARINA BOULEVARD #11 MARINA BAY FINANCIAL CENTRE SINGAPORE (018981) </a
          ><br />

          <b>Email:</b>
          <a href="mailto:info@gipnetix.com"> info@gipnetix.com </a>
        </common-text>
      </div>
    </div>
    <div class="contacts-page__map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.82290372301!2d103.85267981475398!3d1.2798896990664923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190fe0ac9f9d%3A0xb921a13983456c52!2s8A%20Marina%20Blvd%2C%2011%20Marina%20Bay%20Financial%20Centre%2C%20Singapore%20018984!5e0!3m2!1sru!2sru!4v1644171808785!5m2!1sru!2sru"
        width="100%"
        height="608"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
    <app-estimation upper-case-title>
      Have a question
      <template #subtitle> Please contact us </template>
    </app-estimation>
  </section>
</template>

<script>
import CommonTitle from '@/components/ui/CommonTitle'
import CommonText from '@/components/ui/CommonText'
import AppEstimation from '@/components/dump/AppEstimation'

export default {
  name: 'ContactsPage',
  components: {
    AppEstimation,
    CommonText,
    CommonTitle
  }
}
</script>

<style scoped lang="sass">
.contacts-page
  &__introduction
    display: grid
    grid-template-columns: 123fr 526fr 81fr
    background-image: url(~@/assets/img/contacts-itro-bg.png)
    background-position: center bottom
    background-repeat: no-repeat

    @media screen and (max-width: 768px)
      grid-template-columns: $mobile-grid

    &-content
      grid-column-start: 2
      width: 100%
      max-width: rem(1052)
      margin: 305px auto 105px
      display: flex
      flex-direction: column

      @media screen and (max-width: 1023px)
        margin: 100px auto 50px

      .title
        margin-bottom: rem(28)

      .description
        margin-left: auto
        line-height: 1.68
  &__map
    +media((width: (0: calc(100% - 2rem), 768: 82%)))
    margin: 0 auto rem(140)
    max-width: rem(1210)
    border-radius: rem(30)
    overflow: hidden
    position: relative
    &:after
      content: ""
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background: linear-gradient(0deg, #3F3F54, #3F3F54)
      opacity: .7
      pointer-events: none
</style>
