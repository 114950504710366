<template>
  <div class="app-menu">
    <div class="app-menu__head">
      <app-logo />
      <common-text class="app-menu__title"> BUILD. DISTINCT. RELATE. </common-text>
    </div>
    <app-nav />
    <app-link href="#submit" :type="linkTypes.BTN_GHOST_WHITE"> Submit Your Project </app-link>
  </div>
</template>

<script>
import AppLogo from '@/components/ui/AppLogo'
import CommonText from '@/components/ui/CommonText'
import AppNav from '@/components/dump/AppNav'
import AppLink from '@/components/ui/AppLink'
import { LINK_TYPES } from '@/utils/link-types'

export default {
  name: 'AppMenu',
  components: { AppLink, AppNav, CommonText, AppLogo },
  computed: {
    linkTypes: () => LINK_TYPES
  }
}
</script>

<style scoped lang="sass">
.app-menu
  +media((height: (0: 100%, 1200: calc(100% - 100px), 1400: 100%)))
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  &__head
    display: flex
    flex-direction: column
    align-items: center
  &__title
    font-style: normal
    font-weight: 300
    font-size: rem(20)
    line-height: 140%
    text-align: center
    letter-spacing: 0.1em
    color: rgba($white, .9)
    margin: rem(24) auto 0
    max-width: rem(262)
    padding-bottom: rem(38)
    box-shadow: inset 0 rem(-1) 0 rgba($white, .2)
</style>
