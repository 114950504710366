export const routeNames = {
  home: 'Home',
  missingPage: '404',
  service: 'Service',
  contacts: 'Contacts',
  partnership: 'Partnership'
}

export const homeRoute = routeNames.home

export const missingPageRoute = routeNames.missingPage
