<script>
export default {
  name: 'FormError',
  render(createElement) {
    return createElement(
      'span',
      {
        class: 'form-error'
      },
      this.$slots.default
    )
  }
}
</script>

<style scoped lang="sass">
.form-error
  color: red
  font-size: rem(12)
  line-height: 2
</style>
