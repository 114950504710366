export const services = [
  {
    path: 'brand-image-development',
    name: 'Brand Image Development',
    subtitle: 'Market positioning strategy development, Business reputation management',
    synopsis:
      'Innovative digital solutions simplify business processes, helping enterprises be one step' +
      ' ahead of rivals. Our top experts can take care of frontend and backend development at any point in your project evolution.',
    preview: require('@/assets/img/services/service-card-1.png'),
    hero: require('@/assets/img/services/service-1.png'),
    about:
      'Brand image development is vital for any business because that’s exactly what makes it stand out from the crowd.<br /><br /> Think of any big brand and you will be able to link its market positioning to something extremely positive and fun – whether it’s just eating candies in the movie theater, an adorable Christmas truck, a long-awaited festival, or a football star scoring the most important goal in his career.<br /> But how exactly can you develop an epic market positioning strategy?<br /> At Gipnetix, we identify the target market straight off the bat. The second step is strategy development. This stage should be able to position your brand apart from the white noise of your niche and the competition. The particular messages about your services or products must be connected with something rare, or at least very positive and distinctly desirable.',
    aboutImage: require('@/assets/img/services/service-card-1.png'),
    description:
      'The difference between an ordinary brand and an extraordinary brand is just that little “extra”. Brands become distinctive when the clients start recognizing them. Unlike many instruments delivering temporary effect, this is always a strong foundation of their success. At Gipnetix, we use proper market positioning, business reputation management, and incredible design to place people at the center of the positive brand experience.',
    descriptionSecond:
      'The other ultra-important aspect that our experts can handle is business reputation management. Think about the first few online search results related to your brand, and you will agree they can potentially ruin your business or make your brand strong. Gipnetix tracks online conversations referring to their client’s brand, helps to respond and interact with issues promptly, regularly disseminates positive content, and uses various digital media for brand promotion.',
    estimateText:
      'We offer experienced, talented, and dedicated IT experts for exclusive and fully customized software development.'
  },
  {
    path: 'business-analysis',
    name: 'Business Analysis',
    synopsis:
      'Detailed studying of a system and its parts helps to identify its objectives. Computer-based systems demand a thorough analysis. While many projects are not aware of it, this vital component of development and troubleshooting may skyrocket the efficiency of your business.',
    preview: require('@/assets/img/services/service-card-2.png'),
    hero: require('@/assets/img/services/service-2.png'),
    about:
      'It’s no secret every business has its bottlenecks and sooner or later every manager encounters them and finds out what they are.<br /> The success of any project is dependent on how quickly these road bumps can be determined and dealt with. The Gipnetix team has been performing business analysis for many years, and timely risk assessment saved millions of dollars to our clients.<br /> The thorough business analysis includes a huge set of activities including evaluating the potential of the idea in the light of existing trends, increasing the chances of market entry, accelerating the time it takes to launch, and post-release support.<br /> The other vital aspect is the ability to analyze the competitors in advance, ideally before the startup. It always allows us to react appropriately and assure distinct killer features of the product, create viral content, and do other necessary preparations.',
    aboutImage: require('@/assets/img/services/service-card-2.png'),
    description:
      'Business analysis service involves a deep validation of your ideas by the top business experts. It includes the in-depth study of the niche, its trends, the competitors, and searches for possible business partners if needed. It’s a tailor-made solution that will reduce various threats and risks for your project at any stage of development.',
    descriptionSecond:
      'Finally, we can also take care of researching and establishing contacts with potential partners. This process varies a lot from one to another business because the objectives and requirements can be very different and each case is unique. Either way, Gipnetix can handle business partner searches of any complexity.',
    estimateText: 'Ready to cooperate?'
  },
  {
    path: 'social-media-marketing',
    name: 'Social Media Marketing',
    synopsis:
      'How do you meet your deadlines if your business is scaling up and you have no time for team building? Outsourcing services is a simple solution that will find you trustworthy and affordable employees. Many big brands have been using outsourcing in their business models, and today it’s a legal, safe, and beneficial option to expand your company the right way.',
    preview: require('@/assets/img/services/service-card-3.png'),
    hero: require('@/assets/img/services/service-3.png'),
    about:
      'The Gipnetix clients are often ready to invest in their websites, design, and branding.<br/> Many business leaders also understand the value and immense potential of marketing. But surprisingly we sometimes hear that SMM is not a real thing. In reality, any project can benefit from well-directed social media marketing, and now we will explain why.<br/> SMM increases growth in sales and it’s only improving over time, especially since shoppable content is a big trend right now, and it’s an efficient way to bring the shopping experience to a whole new level.<br/> SMM enables making high conversion ads with great flexibility of settings and simple tools for quick data analysis.<br/> SMM helps to build customer loyalty by publishing appealing and useful client-oriented content.<br/>',
    aboutImage: require('@/assets/img/services/service-card-3.png'),
    description:
      'Social media play a significant role in modern life and some businesses rely entirely on SMM promotion. Even if it’s not the case with your business, chances that you are leaving money on the table if you are not exploiting the power of social media marketing. The core of the process primarily consists of developing an SMM strategy and customization of social accounts. From there you will only need to add two more ingredients: hot branded content and smart maintenance.',
    descriptionSecond:
      'SMM allows increasing feedback fast and integrally improves communication with customers.<br/> SMM attracts your target audience and helps to reach new people with the needed interests with just a few clicks.<br/> SMM makes your brand more recognizable and allows you to amplify your brand image development strategy.',
    estimateText:
      'Our experts are known as the industry standard to manage things speedily and with the highest security levels. Cooperation with our team can be a real shortcut to your success.'
  },
  {
    path: 'education',
    name: 'Education',
    synopsis:
      'How do you meet your deadlines if your business is scaling up and you have no time for team building? Outsourcing services is a simple solution that will find you trustworthy and affordable employees. Many big brands have been using outsourcing in their business models, and today it’s a legal, safe, and beneficial option to expand your company the right way.',
    preview: require('@/assets/img/services/service-card-4.png'),
    hero: require('@/assets/img/services/service-4.png'),
    about:
      'All businesses need qualified digital marketers, and one of the simplest solutions is to grow these experts on your own. As usual, the Gipnetix team is ready to do all the heavy lifting. We start with conducting a detailed study of your business processes, staff competencies and analyze communication quality to affirm training goals. Once the objectives are defined and confirmed, our experts coordinate how to measure the inputs and outputs. After that, the Gipnetix team develops training programs resulting in clearly expected corporate benefits.<br/> We often hear the question why exactly it is so hard to find a digital marketing expert. The answer is simple: digitalization of the world is accelerating and online marketing is evolving at the speed of light. It’s not just enough to keep track of the constant changes in the existing trends and master the new features. It’s also necessary to keep an eye on emerging platforms and channels.',
    aboutImage: require('@/assets/img/services/service-card-4.png'),
    description:
      'Successful business leaders know that their business rises by lifting their employees. Corporate training is a wonderful investment in the company’s future. Digital marketing experts are scarce in the labor market, and this is a global issue, so even outsourcing can’t always solve it well enough. Luckily for your company, these in-demand skills can be obtained with the help of online video courses, marketing tutorials, and guides developed for the specific needs of your business entity and niche.',
    descriptionSecond:
      'It is close to impossible to cover all the aspects of fast-changing digital marketing by one person, that’s why our specialists work in a team. Our unique online video courses, special guides, and marketing tutorials created by a group of top experts can be extremely helpful for the professional growth of your staff.<br/> Since every company has a different marketing scenario and functions in its unique circumstances, it’s very beneficial to have the corporate education program tailored to the specific needs of your project.',
    estimateText:
      'Our experts are known as the industry standard to manage things speedily and with the highest security levels. Cooperation with our team can be a real shortcut to your success.'
  },
  {
    path: 'seo',
    name: 'On-site and off-site optimization (SEO)',
    synopsis:
      'How do you meet your deadlines if your business is scaling up and you have no time for team building? Outsourcing services is a simple solution that will find you trustworthy and affordable employees. Many big brands have been using outsourcing in their business models, and today it’s a legal, safe, and beneficial option to expand your company the right way.',
    preview: require('@/assets/img/services/service-card-5.png'),
    hero: require('@/assets/img/services/service-5.png'),
    about:
      'On-site and off-site optimization processes are both important for every business that wants to succeed in the digital era.<br/> Gipnetix has a team of professionals ready to help you manage both of these tasks. But let’s take a closer look at them firstly.<br/> On-site optimization refers to the things needed to be done on your website to improve its visibility on the web. Over 200 ever-evolving factors have a significant impact on how it ranks. It means SEO is not a subject of a quick-fix but rather a fundamental process involving tons of preparations and diligent routine work from semantic content analysis to broken page links and everything in between.<br/> For example, content “freshness” is vital and it implies regular content updates. But this factor alone won’t bring your site to the top. Your content must be relevant and you should build an ideal internal linking system demonstrating the content depth and increasing trust of search engines in your resource.',
    aboutImage: require('@/assets/img/services/service-card-5.png'),
    description:
      'Search engine optimization is certainly one of the foundations for the success of any website. Google, Bing, and other search engines reward the sites optimized in the best way, but unfortunately, it’s usually unclear what exactly it means unless you are an SEO expert who constantly keeps up with ever-changing algorithms. Off-page SEO is even a more complicated set of tools. The off-page optimization process goes far beyond your website enhancing search rankings of your web pages across the web.',
    descriptionSecond:
      'It’s also necessary to keep in mind that on-page SEO is fundamental, and unless it’s performed correctly, all other optimization efforts will be very limited or even vain.<br/> Off-site optimization processes spread far beyond the website which makes things even more complicated. Most of the projects never bother taking care of this aspect, so it can become a competitive advantage of your business if you entrust this process to the Gipnetix experts.',
    estimateText:
      'Our experts are known as the industry standard to manage things speedily and with the highest security levels. Cooperation with our team can be a real shortcut to your success.'
  },
  {
    path: 'graphic-design',
    name: 'Graphic Design',
    synopsis:
      'There are multiple variations of graphic design services, including vector, 3D, infographic, and Photoshop designs. It’s easy to get confused, but we are here to get you covered. Our experienced designers will gladly identify your needs and provide you with the most effective solutions that will also underscore your brand’s message.',

    preview: require('@/assets/img/services/service-card-6.png'),
    hero: require('@/assets/img/services/service-6.png'),
    about:
      'Art production services are vital in the modern world, and high-quality art can raise the conversion rates of nearly any business. It’s easy to get lost among thousands of graphics and design artists and companies, and here’s why you should stick to our reputable agency if you are serious about your project. While the best individual artists are extremely busy, a top design firm can give you a quick response and devote as much attention to your task as possible.',

    aboutImage: require('@/assets/img/services/service-card-6.png'),
    description:
      'In Gipnetix, we have a big team of professionals keen on various styles and' +
      ' numerous graphic design services. We can name concept art, marketing art, branding, ad design, and tons of other visual communication materials.',
    descriptionSecond:
      'A professional approach means that our clients always receive complete and comprehensive solutions. We start from defying business objectives and developing a detailed strategy. At every stage of our cooperation, the customer is aware of the progress. Our friendly team is always proactive, and we deliver effective graphic design of world-class quality.',

    estimateText:
      'We offer experienced, talented, and dedicated IT experts for exclusive and fully customized software development. '
  },
  {
    path: 'digital-marketing',
    name: 'Digital Marketing',
    synopsis:
      'All-in-one marketing service is ready to take your brand or product to the next level. Our dedicated team pays great attention to details. We know which marketing strategies can fuel your growth, and our package solutions will help you achieve your goals in no time.',
    preview: require('@/assets/img/services/service-card-7.png'),
    hero: require('@/assets/img/services/service-7.png'),
    about:
      'Internet marketing is easy to measure because it’s all about the results. Its tools' +
      ' allow you to communicate with the customers in real-time. Engagement with prospects means a possibility to determine their pain points and provide an adequate response quickly. Once you collect enough feedback, it becomes much easier to fine-tune your brand.',
    aboutImage: require('@/assets/img/services/service-card-7.png'),
    description:
      'It’s often said there are no shortcuts to success. But maybe digital marketing is the next best thing because you avoid making the same mistakes twice, thus saving the most precious resources and winning the loyalty of your customers.',
    descriptionSecond:
      'While many aspiring entrepreneurs try to reinvent the wheel, we are sure this is not the right way for you. Professional digital marketers already know the most efficient methods of attracting and delighting customers online, and they are always here to share their knowledge and best practices.\n' +
      'At Gipnetix, we take digital marketing very seriously, as it’s by far the most powerful' +
      ' way to accelerate results and bring the business to a whole new level.',
    estimateText:
      'Our experts are ready to research your customer’s journey, integrate modern technology and media across various marketing activities, and deliver you the most outstanding results.'
  }
]
