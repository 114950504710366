<template>
  <div class="services-list">
    <common-title full-width auto-side-margin class="services-list__title">
      Our Services
    </common-title>
    <MatchMedia v-slot="{ matches }" query="(max-width: 767px)" class="services-list__items">
      <services-list-item
        v-for="service in services"
        :key="service.path"
        :minified="matches"
        :service="service"
      />
    </MatchMedia>
  </div>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries'

import { services } from '@/utils/services'
import ServicesListItem from '@/components/dump/Services/ServicesListItem'
import CommonTitle from '@/components/ui/CommonTitle'

export default {
  name: 'ServicesList',
  components: { CommonTitle, ServicesListItem, MatchMedia },
  computed: {
    services: () => services
  }
}
</script>

<style scoped lang="sass">
.services-list
  display: grid
  position: relative
  +media((grid-template-columns: (0: $mobile-grid, 768: 25fr 234fr 33fr)))
  +media((margin: (0: rem(120) auto, 768: rem(160) auto rem(167))))

  &__title, &__items
    max-width: rem(1170)
    grid-column-start: 2

  &__items
    width: 100%
    display: grid
    margin: 0 auto
    +media((gap: (0: rem(32), 768: rem(40))))
</style>
