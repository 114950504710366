<template>
  <div class="app-breadcrumbs">
    <router-link
      v-for="(breadcrumb, b) in breadcrumbs"
      :key="b"
      class="app-breadcrumbs__item"
      :to="breadcrumb.link"
      v-html="breadcrumb.text"
    >
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'AppBreadcrumbs',
  components: {},
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },
  computed: {}
}
</script>

<style scoped lang="sass">
.app-breadcrumbs
  display: flex
  align-items: center
  padding: 6px 20px
  background: $white
  border-radius: 30px
  width: fit-content

  &__item
    font-weight: 700
    font-size: 20px
    line-height: 140%
    text-decoration: none
    color: $black
    margin-right: 6px

    &:after
      content: '/'
      margin-left: 6px

    &:first-child
      color: #A1A1A1

    &:last-child

      &:after
        display: none

    @media (max-width: 768px)
      font-size: 15px
</style>
