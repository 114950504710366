var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showForm)?_c('ValidationObserver',{staticClass:"app-form__wrapper",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"app-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{staticClass:"app-form__field",attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('app-input',{attrs:{"placeholder":"Your Name","type":"text","is-error":failed},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(errors[0])?_c('form-error',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{staticClass:"app-form__field",attrs:{"rules":"required|email","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('app-input',{attrs:{"placeholder":"Your Email","type":"text","is-error":failed},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(errors[0])?_c('form-error',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"app-form__field"},[_c('app-textarea',{attrs:{"placeholder":"What idea do you have?"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('app-button',{attrs:{"is-loading":_vm.isLoading},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/img/arrow-white-right.svg"),"alt":"→"}})]},proxy:true}],null,true)},[_vm._v(" Submit ")])],1)]}}],null,false,3974918962)}):_c('div',{staticClass:"app-form__thx"},[_c('common-subtitle',[_c('b',[_vm._v("Thanks!")])]),_c('common-text',[_vm._v(" Your message has been sent. We will back to you soon. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }