<script>
export default {
  name: 'CommonSubtitle',
  props: {
    disableMargin: {
      type: Boolean
    },
    isBold: {
      type: Boolean
    },
    isUppercase: {
      type: Boolean
    }
  },
  render(createElement) {
    return createElement(
      'div',
      {
        class: {
          'common-subtitle': true,
          'common-subtitle--no-margin': this.disableMargin,
          'common-subtitle--bold': this.isBold,
          'common-subtitle--uppercase': this.isUppercase
        }
      },
      this.$slots.default
    )
  }
}
</script>

<style scoped lang="sass">
.common-subtitle
  font-style: normal
  font-weight: 300
  +media((font-size: (0: rem(18), 768: rem(24))))
  line-height: 140%
  color: $black
  text-align: left
  &:not(&--no-margin)
    margin-bottom: rem(18)
  &--bold
    font-weight:  800
  &--uppercase
    text-transform: uppercase
</style>
