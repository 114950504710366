<template>
  <section
    class="spi-introduction"
    :style="{ '--hero': `url(${service.hero})` }"
    :class="{ 'spi-introduction--with-subtitle': service.subtitle }"
  >
    <common-title class="spi-introduction__title" disable-margin>
      {{ service.name }}
    </common-title>
    <p v-if="service.subtitle" class="spi-introduction__subtitle">
      {{ service.subtitle }}
    </p>
  </section>
</template>

<script>
import CommonTitle from '@/components/ui/CommonTitle'

export default {
  name: 'ServicePageIntroduction',
  components: { CommonTitle },
  props: {
    service: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
.spi-introduction
  max-width: rem(1208)
  margin: 0 auto
  width: 100%
  position: relative
  padding: 0 2rem
  margin-bottom: rem(290)

  @media screen and (max-width: 1023px)
    margin-bottom: rem(50)

  @media screen and (max-width: 767px)
    margin-bottom: rem(230)

  &:after
    // content: var(--hero)
    content: ''
    background-image: var(--hero)
    background-repeat: no-repeat
    background-size: cover
    width: 38vw
    height: 38vw
    max-width: 648px
    max-height: 648px
    position: absolute
    right: 6vw
    top: 50%
    transform: translate(10%, -50%)

    @media screen and (max-width: 767px)
      width: 74vw
      height: 64vw
      transform: translate(-20%, 5%)

  &--with-subtitle
    @media screen and (max-width: 767px)
      &:after
        transform: translate(-20%, 30%)

  &__title
    max-width: rem(500)
    margin-top: rem(317)
    position: relative
    z-index: 2

    @media screen and (max-width: 1023px)
      margin-top: rem(50)

    @media screen and (max-width: 767px)
      max-width: 82%

  &__subtitle
    font-weight: 800
    +media((font-size: (0: rem(18), 768: rem(28))))
    line-height: 140%
    text-transform: capitalize
    max-width: 527px
    z-index: 1
    position: relative

    @media screen and (max-width: 767px)
      max-width: 82%
</style>
