<template>
  <textarea v-bind="$attrs" class="app-textarea" @input="onInput" @chage="onChange"></textarea>
</template>

<script>
export default {
  name: 'AppTextarea',
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    onChange(e) {
      this.$emit('change', e.target.value)
    }
  }
}
</script>

<style scoped lang="sass">
.app-textarea
  @extend %form-field-styles
  min-height: rem(76)
  resize: none
</style>
