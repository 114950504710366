<template>
  <section class="missing-page">
    <div class="content">
      <div class="content__left">
        <common-title disable-margin>404</common-title>
      </div>
      <div class="content__right">
        <common-title disable-margin>SORRY!</common-title>
        <common-text> The page You're Looking for Was Not Found </common-text>
        <app-router-link to="/" :type="linkTypes.BTN_BLACK">Home</app-router-link>
      </div>
    </div>
  </section>
</template>

<script>
import CommonTitle from '@/components/ui/CommonTitle'
import CommonText from '@/components/ui/CommonText'
import AppRouterLink from '@/components/HOCS/AppRouterLink'
import { LINK_TYPES } from '@/utils/link-types'

export default {
  name: 'MissingPage',
  components: { AppRouterLink, CommonText, CommonTitle },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  computed: {
    linkTypes: () => LINK_TYPES
  }
}
</script>

<style scoped lang="sass">
.missing-page
  height: 100%
  display: flex
  align-items: center

  .content
    @extend %common-container
    @extend %common-offsets
    display: flex
    justify-content: center
    align-items: center
    +media((flex-direction: (0: column, 768: row)))
    &__left
      +media((box-shadow: (768: inset rem(-1) 0 0 $black)))
      +media((padding-right: (768: rem(50))))
      //+media((margin-bottom: (0: rem(50), 768: 0)))
      .common-title
        font-size: rem(140)
    &__right
      +media((text-align: (0: center, 768: left)))
      +media((padding-left: (768: rem(50))))
      .common-text
        margin: rem(24) 0
</style>
