<template>
  <section class="spa-about">
    <div class="spa-about__content">
      <div class="spa-about__content-top">
        <div class="image">
          <img :src="service.aboutImage" alt="image" />
        </div>
        <div class="description">
          <common-subtitle is-bold> About the service </common-subtitle>
          <common-text v-html="service.about"></common-text>
        </div>
      </div>
      <div class="spa-about__content-bottom">
        <div class="description">
          <common-text>
            {{ service.description }}
          </common-text>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CommonText from '@/components/ui/CommonText'
import CommonSubtitle from '@/components/ui/CommonSubtitle'

export default {
  name: 'ServicePageAbout',
  components: { CommonSubtitle, CommonText },
  props: {
    service: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="sass">
.spa-about
  display: grid
  grid-template-columns: 1fr 6fr 1fr
  margin-bottom: rem(74)
  z-index: 1
  position: relative

  @media screen and (max-width: 768px)
    grid-template-columns: $mobile-grid
    margin-bottom: rem(40)

  &__content
    grid-column-start: 2
    max-width: rem(1208)
    width: 100%
    margin: 0 auto

    &-top
      gap: 10%
      display: flex
      align-items: center
      margin-bottom: rem(160)
      min-width: 210px

      @media screen and (max-width: 1600px)
        flex-direction: column-reverse
        align-items: flex-start

      @media screen and (max-width: 1023px)
        flex-direction: column-reverse
        padding-right: 0
        align-items: flex-start
        margin-bottom: rem(40)

      .image
        max-width: 35vw
        line-height: 0
        border-radius: rem(30)
        overflow: hidden
        flex: 0 0 50%

        @media screen and (max-width: 1600px)
          margin-top: 2.5rem
          max-width: none

        @media screen and (max-width: 1023px)
          max-width: 100%

          margin-top: rem(40)

        img
          width: 100%
          height: auto
      .description

    &-bottom

      .description
        max-width: rem(480)

.description
  max-width: 30rem
  min-width: 260px
</style>
