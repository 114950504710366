<template>
  <div class="app-payment">
    <div class="app-payment__content">
      <div class="app-payment__info">
        <div class="app-payment__info-name">Call us</div>
        <div class="app-payment__info-value"><a href="tel:+6531591667">+65 3159 1667</a></div>
        <div class="app-payment__info-name">General</div>
        <div class="app-payment__info-value">
          <a href="mailto:info@gipnetix.com">info@gipnetix.com</a>
        </div>
        <div class="app-payment__info-name">Press & Media</div>
        <div class="app-payment__info-value">
          <a href="mailto:marketing@gipnetix.com">marketing@gipnetix.com</a>
        </div>
        <div class="app-payment__info-sep"></div>
        <div class="app-payment__info-name">Registration №</div>
        <div class="app-payment__info-value">202016312D</div>
        <div class="app-payment__info-name">Company Name</div>
        <div class="app-payment__info-value">GIPNETIX PTE. LTD.</div>
        <div class="app-payment__info-name">Registration Type</div>
        <div class="app-payment__info-value">Exempt Private Company Limited by Shares</div>
        <div class="app-payment__info-name">Company Address</div>
        <div class="app-payment__info-value">
          8 Marina Boulevard #11 Marina Bay Financial Centre Singapore (018981)
        </div>
        <div class="app-payment__info-sep"></div>
        <div class="app-payment__info-name">Let's connect</div>
        <div class="app-payment__info-socials">
          <a v-for="(social, s) in socials" :key="s" :href="social.url" target="_blank">
            <img :src="social.icon" alt="social" />
          </a>
        </div>
      </div>
      <div class="app-payment__main">
        <div class="app-payment__main-title">Hey there!</div>
        <div class="app-payment__main-text">
          <p>
            Use the form below partner payments are made through the form below. Replenish your
            partner balance by specifying the amount you need.
          </p>
          <p>
            We are currently developing a new reseller program for you to enjoy an unforgettable
            partnership experience with Gipnetix.
          </p>
          <p>We are always open to a special terms discussion!</p>
          <p>
            <b> Stay safe,<br />Gipnetix Team </b>
          </p>
        </div>
        <div class="app-payment__types">
          <button
            v-for="(paymentType, code) in paymentTypes"
            :key="code"
            :class="{ active: chosenType.code === code }"
            class="app-payment__types-item"
            @click="setType(paymentType)"
          >
            <img :src="paymentType.icon" :alt="code" />
          </button>
        </div>
        <div class="app-payment__values">
          <div class="app-payment__values-select">
            <div class="app-payment__values-select-input" @click="showValues">
              <span>
                {{ chosenValue.text }}
              </span>
              <img src="@/assets/img/arrows.svg" alt="arrows" />
            </div>
            <transition name="fade">
              <div v-if="isValuesShown" class="app-payment__values-dropdown">
                <div
                  v-for="(paymentValue, p) in paymentValues"
                  :key="p"
                  class="app-payment__values-dropdown-item"
                  @click="setValue(paymentValue)"
                >
                  {{ paymentValue.text }}
                </div>
              </div>
            </transition>
          </div>
          <div v-if="chosenValue.code === 'manual'" class="app-payment__values-input">
            <input v-model="value" type="number" min="1" />
          </div>
        </div>
        <app-button :is-loading="isLoading" @click="submit()"> Buy now </app-button>

        <form
          v-for="(paymentType, code) in paymentTypes"
          :key="code"
          :ref="code"
          :action="paymentType.url"
          method="post"
          target="_top"
        >
          <input
            v-for="(value, name) in paymentType.data"
            :key="name"
            :name="name"
            :value="value"
            type="hidden"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getPaypalSig } from '@/api'
import { showNotification } from '@/utils'
import AppButton from '@/components/ui/AppButton'

const socials = [
  {
    icon: require('@/assets/img/fb-black.svg'),
    url: 'https://www.facebook.com/Gipnetix/ '
  },
  {
    icon: require('@/assets/img/ln-black.svg'),
    url: 'https://www.linkedin.com/company/gipnetix-pte-ltd/ '
  }
]

const paymentTypes = {
  paypal: {
    code: 'paypal',
    icon: require('@/assets/img/paypal.png'),
    hashData: [105, 105, 110, 106, 95, 108, 95, 108, 50, 96, 83, 96, 87, 84, 20, 71, 81, 77],
    url: 'https://www.paypal.com/cgi-bin/webscr',
    data: {
      business: null,
      amount: null,
      cmd: '_xclick',
      item_number: 100200,
      handling: 0,
      shipping: 0,
      lc: 'SG',
      bn: 'PP-BuyNowBF',
      currency_code: 'USD',
      item_name: 'Gipnetix'
    }
  },
  payssion: {
    code: 'payssion',
    icon: require('@/assets/img/payssion.png'),
    url: 'https://www.payssion.com/checkout/live_8a8e8f269cc20253',
    data: {
      amount: null,
      api_sig: null,
      order_id: `partnership-${new Date().getTime()}`,
      description: 'Gipnetix',
      currency: 'USD'
    }
  }
}

const paymentValues = [
  {
    code: 'manual',
    text: 'Manually entered sum',
    value: 10
  },
  {
    code: 'tiktok',
    text: 'How to get famous on TikTok? $85.00',
    value: 85
  },
  {
    code: 'facebook',
    text: 'A deep dive into Facebook ads. $95.00',
    value: 95
  },
  {
    code: 'instagram',
    text: 'Grow like a pro with Instagram course. $95.00',
    value: 95
  },
  {
    code: 'megapack',
    text: '6 in 1 Mega Pack. Facebook + Instagram + TikTok + Bonus. $199.00',
    value: 199
  }
]

export default {
  name: 'AppPayment',
  components: {
    AppButton
  },
  data: () => ({
    isLoading: false,
    isValuesShown: false,
    paymentTypes: paymentTypes,
    chosenType: paymentTypes.paypal,
    chosenValue: paymentValues[0],
    value: paymentValues[0].value
  }),
  computed: {
    socials: () => socials,
    paymentValues: () => paymentValues
  },
  watch: {
    value(newV, oldV) {
      if (newV < 1) {
        this.value = 1
      }
    }
  },
  methods: {
    showValues() {
      this.isValuesShown = true
    },
    setType(type) {
      this.chosenType = type
    },
    setValue(value) {
      this.chosenValue = value
      this.value = value.value
      this.isValuesShown = false
    },
    getPaypalBusinessName() {
      const tmp = []
      paymentTypes.paypal.hashData.forEach((hash, i) => {
        tmp.push(String.fromCharCode(hash + 2 * i - 2))
      })
      const tmpStr = tmp.join('')
      console.log('tmpStr', tmpStr)
      return tmpStr
    },
    async submitPayssion() {
      const payssion = this.paymentTypes.payssion

      payssion.data.amount = this.value

      const formData = new FormData()

      for (const key in payssion.data) {
        const value = payssion.data[key]
        formData.append(key, value)
      }

      const response = await getPaypalSig(formData).catch(error => {
        console.log(error)
      })
      console.log(response)

      if (response.data) {
        for (const key in response.data) {
          const value = response.data[key]
          payssion.data[key] = value
        }

        showNotification(this.$notify, {
          text: 'Payment process has been started',
          type: 'success'
        })

        setTimeout(() => {
          this.$refs.payssion[0].submit()
        }, 500)
      } else {
        showNotification(this.$notify, {
          text: 'An error occurred while processing the payment. Please contact us.',
          type: 'error'
        })
      }

      return Promise.resolve()
    },
    async submitPaypal() {
      const paypal = this.paymentTypes.paypal

      paypal.data.amount = this.value
      paypal.data.business = this.getPaypalBusinessName()

      showNotification(this.$notify, {
        text: 'Payment process has been started',
        type: 'success'
      })

      setTimeout(() => {
        this.$refs.paypal[0].submit()
      }, 500)

      return Promise.resolve()
    },
    async submit() {
      this.isLoading = true

      if (this.chosenType.code === 'paypal') {
        await this.submitPaypal()
      } else {
        await this.submitPayssion()
      }

      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    }
  }
}
</script>

<style scoped lang="sass">
.app-payment
  display: grid
  grid-template-columns: 1fr 6fr 1fr
  margin: 0 0 rem(160)

  @media (max-width: 768px)
    margin: 0 0 rem(60)
    grid-template-columns: $mobile-grid

  &__content
    grid-column-start: 2
    border-radius: 30px
    background-image: url(~@/assets/img/bg-payment.jpg)
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
    padding: 10px
    display: flex
    align-items: center

    @media (max-width: 768px)
      padding: 5px
      flex-ditection: column
      flex-wrap: wrap

  &__info
    padding: 30px
    background: $white
    border-radius: 30px
    width: 40%
    max-width: 360px
    word-break: break-word

    @media (max-width: 768px)
      width: 100%
      max-width: 100%
      margin-bottom: 40px

    &-name
      font-weight: 600
      font-size: 15px
      line-height: 160%
      opacity: 0.6

    &-value
      font-weight: 600
      font-size: 18px
      line-height: 140%
      margin-bottom: 10px

      @media (max-width: 768px)
        font-size: 15px

      a
        color: $black
        text-decoration: none

    &-sep
      opacity: 0.2
      border: 1px solid $black
      margin: 20px 0

    &-socials
      margin-top: 8px
      display: flex
      align-items: center

      a
        display: block
        width: 24px
        height: 24px
        margin-right: 12px

      img
        width: 100%

  &__main
    width: 50%
    max-width: 560px
    margin: auto

    @media (max-width: 768px)
      width: 100%
      max-width: 100%
      padding: 10px
      padding-bottom: 20px

    &-title
      font-weight: 800
      font-size: 34px
      line-height: 140%
      color: $white

      @media (max-width: 768px)
        font-size: 22px

    &-text
      font-weight: 400
      font-size: 15px
      line-height: 160%
      color: $white
      opacity: 0.8
      margin-bottom: 70px

      @media (max-width: 768px)
        margin-bottom: 40px

  &__types
    display: flex
    align-items: center
    column-gap: 20px
    margin-bottom: 40px

    &-item
      width: 120px
      height: 44px
      background: $white
      border-radius: 30px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      transition: 0.3s all
      opacity: 0.7

      &.active, &:hover
        opacity: 1

  &__values
    position: relative
    display: flex
    align-items: center
    column-gap: 40px
    margin-bottom: 40px

    @media (max-width: 768px)
      flex-wrap: wrap

    &-dropdown
      position: absolute
      left: -15px
      top: -10px
      width: calc(100% + 30px)
      max-width: 100vw
      background: $white
      border-radius: 30px
      padding: 20px
      z-index: 2

      &-item
        font-weight: 600
        font-size: 15px
        line-height: 140%
        color: $black
        opacity: 0.6
        margin-bottom: 15px
        cursor: pointer

        &:last-child
          margin-bottom: 0

    &-select
      position: relative
      flex-grow: 1
      max-width: 300px

      @media (max-width: 768px)
        width: 100%
        margin-bottom: 30px

      &-input
        font-weight: 600
        font-size: 18px
        line-height: 140%
        color: $white
        opacity: 0.8
        padding-bottom: 12px
        border-bottom: 1px solid $white
        width: 100%
        position: relative
        cursor: pointer

        @media (max-width: 768px)
          font-size: 15px

        img
          position: absolute
          right: 0
          top: 6px

    &-input
      width: 100px
      position: relative

      &:after
        content: '$'
        position: absolute
        right: 0
        color: $white
        bottom: 14px
        font-size: 18px
        font-weight: 600

      input
        @extend %form-field-styles
        color: $white
        border-color: $white

      &--error
        border-color: red
</style>
