<template>
  <section id="submit" class="app-estimation">
    <common-title class="app-estimation__title" :is-uppercase="upperCaseTitle">
      <slot>Estimate Your Project</slot>
    </common-title>
    <div class="app-estimation__content">
      <div class="app-estimation__content-left">
        <common-subtitle v-if="showSubTitle" is-uppercase>
          <slot name="subtitle">
            We help people create great things. <br /><br />
            <b>Are you ready?</b>
          </slot>
        </common-subtitle>
      </div>
      <div class="app-estimation-right">
        <app-form />
      </div>
    </div>
  </section>
</template>

<script>
import CommonTitle from '@/components/ui/CommonTitle'
import CommonSubtitle from '@/components/ui/CommonSubtitle'
import AppForm from '@/components/ui/AppForm'

export default {
  name: 'AppEstimation',
  components: { AppForm, CommonSubtitle, CommonTitle },
  props: {
    upperCaseTitle: {
      type: Boolean
    },
    showSubTitle: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="sass">
.app-estimation
  display: grid
  +media((grid-template-columns: (0: $mobile-grid, 768: 1fr 6fr 1fr)))

  &__title, &__content
    grid-column-start: 2

  &__content
    display: flex
    +media((align-items: (768: flex-start)))
    +media((flex-direction: (0: column, 1024: row)))
    max-width: rem(1054)
    width: 100%
    justify-content: space-between

    &-left
      +media((max-width: (768: rem(356))))

      min-width: 250px
      margin-right: 15px

    &-rigth
      width: 100%
</style>
