<template>
  <input
    v-bind="$attrs"
    class="app-input"
    :class="{ 'app-input--error': isError }"
    @input="onInput"
    @chage="onChange"
  />
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    isError: {
      type: Boolean
    }
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value)
    },
    onChange(e) {
      this.$emit('change', e.target.value)
    }
  }
}
</script>

<style scoped lang="sass">
.app-input

  overflow: hidden
  text-overflow: ellipsis

  @extend %form-field-styles

  &--error
    border-color: red

    &::placeholder
      color: red
</style>
