<template>
  <a :class="classes" v-bind="$attrs">
    <span v-if="$slots.prepend" class="app-link__prepend">
      <slot name="prepend" />
    </span>
    <slot />
    <span v-if="$slots.append" class="app-link__append">
      <slot name="append" />
    </span>
  </a>
</template>

<script>
import { LINK_TYPES } from '@/utils/link-types'

export default {
  name: 'AppLink',
  props: {
    type: {
      type: String,
      validator: v => Object.values(LINK_TYPES).includes(v),
      default: 'default'
    },
    likeBtn: {
      type: Boolean
    }
  },
  computed: {
    classes() {
      return {
        'app-link': true,
        [`app-link--${this.type}`]: this.type
      }
    }
  }
}
</script>

<style scoped lang="sass">
.app-link
  transition: .3s
  display: inline-flex
  gap: rem(14)
  align-items: center
  text-decoration: none

  &--btn-ghost-white, &--btn-black, &--btn-ghost-black
    @extend %link-like-btn

  &--btn-ghost-white
    @extend %btn-ghost-white

  &--btn-ghost-black
    @extend %btn-ghost-black

  &--btn-black
    @extend %btn-black

  &--white
    font-style: normal
    font-weight: 300
    font-size: rem(12)
    line-height: 140%
    text-transform: uppercase
    color: #BBBBBB
</style>
