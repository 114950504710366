<template>
  <footer class="app-footer">
    <div class="app-footer__content">
      <div class="app-footer__content-left">
        <app-logo size="sm" />
        <div class="links">
          <app-link
            v-for="link in footerLinks"
            :key="link.id"
            :href="link.url"
            :type="linkTypes.WHITE"
            target="_blank"
          >
            {{ link.name }}
          </app-link>
        </div>
      </div>
      <div class="app-footer__content-right">
        <common-text color="white" size="xs">
          Connect with us on your <br />favorite social media channels
        </common-text>
        <div class="links">
          <app-link
            v-for="link in footerSocialLinks"
            :key="link.id"
            :href="link.url"
            target="_blank"
          >
            <img :src="link.icon" alt="icon" />
          </app-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import AppLogo from '@/components/ui/AppLogo'
import AppLink from '@/components/ui/AppLink'
import CommonText from '@/components/ui/CommonText'
import { LINK_TYPES } from '@/utils/link-types'

const FOOTER_LINKS = [
  {
    id: 0,
    name: 'Privacy policy',
    url: ''
  },
  {
    id: 1,
    name: 'Refund policy',
    url: ''
  },
  {
    id: 2,
    name: 'Cookies policy',
    url: ''
  },
  {
    id: 3,
    name: 'Newsletter policy',
    url: ''
  },
  {
    id: 4,
    name: 'Terms and conditions',
    url: ''
  },
  {
    id: 5,
    name: 'Partnership',
    url: ''
  }
]

const FOOTER_SOCIAL_LINKS = [
  {
    id: 0,
    icon: require('@/assets/img/fb.svg'),
    url: 'https://www.facebook.com/Gipnetix/ '
  },
  {
    id: 1,
    icon: require('@/assets/img/ln.svg'),
    url: 'https://www.linkedin.com/company/gipnetix-pte-ltd/ '
  }
  // {
  //   id: 2,
  //   icon: require('@/assets/img/inst.svg'),
  //   url: 'https://www.instagram.com/'
  // }
]

export default {
  name: 'AppFooter',
  components: { CommonText, AppLink, AppLogo },
  computed: {
    footerLinks: () => FOOTER_LINKS,
    footerSocialLinks: () => FOOTER_SOCIAL_LINKS,
    linkTypes: () => LINK_TYPES
  }
}
</script>

<style scoped lang="sass">
.app-footer
  margin-top: rem(120)
  background: $black
  display: grid
  padding: rem(28) 0
  +media((grid-template-columns: (0: $mobile-grid, 768: 7fr 132fr 7fr)))
  &__content
    grid-column-start: 2
    width: 100%
    max-width: rem(1460)
    margin: 0 auto
    display: flex
    +media((flex-direction: (0: column, 768: row)))
    align-items: center
    gap: rem(60)
    justify-content: space-between

    &-left, &-right
      flex-wrap: wrap
      display: flex
      align-items: center
      +media((justify-content: (0: center, 768: flex-start)))

      .common-text
        +media((text-align: (0: center, 768: left)))

    &-left
      gap: rem(40) rem(60)
      .links
        display: grid
        grid-template-columns: 1fr 1fr
        gap: rem(8) rem(60)
        display: none

    &-right
      gap: rem(40)
      .links
        display: flex
        align-items: center
        gap: rem(47)
</style>
