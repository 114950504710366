<template>
  <app-router-link to="/">
    <img :src="logo" :width="imageWidth" :alt="siteName" />
  </app-router-link>
</template>

<script>
import AppRouterLink from '@/components/HOCS/AppRouterLink'
import { siteName } from '@/utils'

const LOGO_SIZES = {
  SM: 'sm',
  MD: 'md'
}

export default {
  name: 'AppLogo',
  components: { AppRouterLink },
  props: {
    size: {
      type: String,
      validator: v => Object.values(LOGO_SIZES).includes(v),
      default: 'md'
    },
    color: {
      type: String,
      default: 'white',
      validator: v => ['black', 'white'].includes(v)
    }
  },
  computed: {
    siteName: () => siteName,
    logo() {
      return require(`@/assets/img/logo--${this.color}.svg`)
    },
    imageWidth() {
      const { size } = this
      const { MD, SM } = LOGO_SIZES
      const [width] = [size === MD && 158, size === SM && 115].filter(Boolean)

      return width
    }
  }
}
</script>

<style scoped></style>
