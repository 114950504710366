<template>
  <div class="service-list-item" :class="{ 'service-list-item--minified': minified }">
    <div
      class="service-list-item__image"
      :style="{
        'background-image': `url(${service.preview})`
      }"
    ></div>
    <div class="service-list-item__content">
      <common-subtitle :disable-margin="minified" is-bold>
        <router-link :to="`/services/${service.path}`">
          {{ service.name }}
        </router-link>
      </common-subtitle>
      <template v-if="!minified">
        <common-text size="sm">
          {{ service.description }}
        </common-text>
        <app-router-link
          :to="`/services/${service.path}`"
          :type="linkTypes.BTN_GHOST_BLACK"
          class="link"
        >
          Details
        </app-router-link>
      </template>
    </div>
  </div>
</template>

<script>
import CommonText from '@/components/ui/CommonText'
import AppRouterLink from '@/components/HOCS/AppRouterLink'
import CommonSubtitle from '@/components/ui/CommonSubtitle'
import { LINK_TYPES } from '@/utils/link-types'

export default {
  name: 'ServicesListItem',
  components: { CommonSubtitle, AppRouterLink, CommonText },
  props: {
    service: {
      type: Object,
      required: true
    },
    minified: {
      type: Boolean
    }
  },
  computed: {
    linkTypes: () => LINK_TYPES
  }
}
</script>

<style scoped lang="sass">
.service-list-item
  display: flex
  background: $white
  width: 100%
  overflow: hidden

  a
    text-decoration: none
    color: initial

  &:not(&--minified)
    border-radius: rem(30)
    &:nth-child(2n + 1)
      .service-list-item__content
        padding: rem(50) 15% rem(50) 10%
    &:nth-child(2n)
      flex-direction: row-reverse
      .service-list-item__content
        padding: rem(50) 10% rem(50) 15%

  &--minified
    border-radius: rem(20)

  &__image
    width: 40%
    background-size: cover
    background-position: center
    .service-list-item--minified &
      width: 30%

  &__content
    width: 60%
    .service-list-item--minified &
      width: 70%
      padding: rem(40) 7% rem(40) 7%

    .link
      margin-top: rem(38)
</style>
