<template>
  <ValidationObserver v-if="showForm" v-slot="{ handleSubmit }" class="app-form__wrapper" tag="div">
    <form class="app-form" @submit.prevent="handleSubmit(onSubmit)">
      <validation-provider
        v-slot="{ failed, errors }"
        rules="required"
        tag="div"
        class="app-form__field"
      >
        <app-input v-model="name" placeholder="Your Name" type="text" :is-error="failed" />
        <form-error v-if="errors[0]" class="error">{{ errors[0] }}</form-error>
      </validation-provider>

      <validation-provider
        v-slot="{ failed, errors }"
        rules="required|email"
        tag="div"
        class="app-form__field"
      >
        <app-input v-model="email" placeholder="Your Email" type="text" :is-error="failed" />
        <form-error v-if="errors[0]" class="error">{{ errors[0] }}</form-error>
      </validation-provider>

      <div class="app-form__field">
        <app-textarea v-model="message" placeholder="What idea do you have?" />
      </div>

      <app-button :is-loading="isLoading">
        Submit
        <template #append>
          <img src="@/assets/img/arrow-white-right.svg" alt="→" />
        </template>
      </app-button>
    </form>
  </ValidationObserver>
  <div v-else class="app-form__thx">
    <common-subtitle> <b>Thanks!</b> </common-subtitle>
    <common-text> Your message has been sent. We will back to you soon. </common-text>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

import AppInput from '@/components/ui/AppInput'
import AppButton from '@/components/ui/AppButton'
import FormError from '@/components/ui/FormError'
import AppTextarea from '@/components/ui/AppTextarea'
import { sendDataToEmail } from '@/api'
import { showNotification } from '@/utils'
import CommonSubtitle from '@/components/ui/CommonSubtitle'
import CommonText from '@/components/ui/CommonText'

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'Email is not valid'
})

export default {
  name: 'AppForm',
  components: {
    CommonText,
    CommonSubtitle,
    AppTextarea,
    FormError,
    AppButton,
    AppInput,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    name: '',
    email: '',
    message: '',
    isLoading: false,
    showForm: true
  }),
  methods: {
    onSubmit() {
      this.isLoading = true
      const formData = new FormData()
      formData.append('email', this.email)
      formData.append('name', this.name)
      formData.append('message', this.message)
      formData.append('from', 'gipnetix')
      sendDataToEmail(formData)
        .then(() => {
          this.isLoading = false
          this.showForm = false
          showNotification(this.$notify, {
            text: 'Your message was sent successfully, Thanks!',
            type: 'success'
          })
        })
        .catch(() => {
          this.isLoading = false
          showNotification(this.$notify, {
            text: 'An error occurred. Please try again later.',
            type: 'error'
          })
        })
    }
  }
}
</script>

<style scoped lang="sass">
.app-form
  display: flex
  flex-direction: column
  align-items: flex-start
  +media((gap: (0: rem(20), 768: rem(40))))
  +media((width: (0: 100%, 768: rem(440))))
  justify-content: flex-start
  position: relative

  &__wrapper
    width: 100%

  &__field
    position: relative
    width: 100%

  .error
    position: absolute
    left: 0
    bottom: 0
    transform: translateY(100%)

  &__thx
    max-width: rem(440)
</style>
