<template>
  <div class="app-mobile-menu">
    <div class="app-mobile-menu__menu">
      <app-menu />
    </div>
  </div>
</template>

<script>
import AppMenu from '@/components/dump/AppMenu'

export default {
  name: 'AppMobileMenu',
  components: { AppMenu }
}
</script>

<style scoped lang="sass">
.app-mobile-menu
  position: fixed
  min-height: 100vh
  height: 100%
  top: 0
  right: 0
  display: flex
  justify-content: flex-end
  z-index: 10
  &__menu
    padding: rem(62) rem(20)
    box-sizing: border-box
    width: rem(420)
    max-width: 80vw
    background-image: url(~@/assets/img/bg.jpg)
    background-size: cover
    background-attachment: fixed
    background-position: right
    height: 100%
    overflow-y: scroll
    +media((min-height: (0: fit-content, 768: auto)))
</style>
