import { render, staticRenderFns } from "./OtherServices.vue?vue&type=template&id=16050532&scoped=true&"
import script from "./OtherServices.vue?vue&type=script&lang=js&"
export * from "./OtherServices.vue?vue&type=script&lang=js&"
import style0 from "./OtherServices.vue?vue&type=style&index=0&id=16050532&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16050532",
  null
  
)

export default component.exports