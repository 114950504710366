<template>
  <section class="hpi-introduction">
    <div class="hpi-introduction__content">
      <common-title class="hpi-introduction__title" disable-margin>
        EVOLVE <span>WITH US</span>
      </common-title>
      <div class="hpi-introduction__description">
        <app-link href="#meet" :type="linkTypes.BTN_BLACK">
          Details
          <template #append>
            <img src="@/assets/img/arrow-white-right.svg" alt="→" />
          </template>
        </app-link>
        <common-text color="black">
          <b>Create</b> powerful strategy <br />
          <b>Develop</b> efficient instruments <br />
          <b>Strengthen</b> your purpose <br />
          <b>Build</b> trust with audience <br />
          <b>Show</b> the vision
        </common-text>
      </div>
    </div>
  </section>
</template>

<script>
import CommonTitle from '@/components/ui/CommonTitle'
import AppLink from '@/components/ui/AppLink'
import CommonText from '@/components/ui/CommonText'
import { LINK_TYPES } from '@/utils/link-types'

// +media((margin: (0: rem(120) 0 rem(60), 768: rem(357) 0 rem(212))))
export default {
  name: 'HomePageIntroduction',
  components: { CommonText, AppLink, CommonTitle },
  computed: {
    linkTypes: () => LINK_TYPES
  }
}
</script>

<style scoped lang="sass">
.hpi-introduction
  +media((min-height: (0: 90vh, 768: rem(963))))
  background-image: url(~@/assets/img/home-intro-bg.png)
  background-position: center center
  background-repeat: no-repeat
  +media((background-size: (0: cover, 768: auto)))

  &__content
    display: grid
    +media((grid-template-columns: (0: $mobile-grid, 768: 4fr 19fr 7fr)))
  &__title
    margin: 355px 0 213px
    grid-column-start: 2
    span
      font-weight: 300

    @media screen and (max-width: 767px)
      margin: 100px 0 50px

  &__description
    width: 100%
    justify-content: space-between
    align-items: flex-start
    grid-column-start: 2
    display: flex
    flex-wrap: wrap
    gap: rem(20)
</style>
