<script>
export default {
  name: 'CommonTitle',
  props: {
    disableMargin: {
      type: Boolean
    },
    autoSideMargin: {
      type: Boolean
    },
    fullWidth: {
      type: Boolean
    },
    isUppercase: {
      type: Boolean
    }
  },
  render(createElement) {
    return createElement(
      'div',
      {
        class: {
          'common-title': true,
          'common-title--no-margin': this.disableMargin,
          'common-title--auto-side-margin': this.autoSideMargin,
          'common-title--full-width': this.fullWidth,
          'common-title--uppercase': this.isUppercase
        }
      },
      this.$slots.default
    )
  }
}
</script>

<style scoped lang="sass">
.common-title
  font-style: normal
  font-weight: 900
  +media((font-size: (0: rem(32), 768: rem(68))))
  line-height: 140%
  letter-spacing: 0.02em
  color: $black
  text-align: left
  &:not(&--no-margin)
    +media((margin-bottom: (0: rem(32), 768: rem(50))))
  &--auto-side-margin
    margin-left: auto
    margin-right: auto
  &--full-width
    width: 100%
  &--uppercase
    text-transform: uppercase
</style>
