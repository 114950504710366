<template>
  <button :class="classes" @click="handle">
    <svg width="100" height="100" viewBox="0 0 100 100">
      <path
        class="line line--1"
        stroke-linecap="round"
        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
      />
      <path class="line line--2" d="M 20,50 H 80" stroke-linecap="round" />
      <path
        stroke-linecap="round"
        class="line line--3"
        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'MenuToggle',
  props: {
    isMenuOpen: {
      type: Boolean
    }
  },
  computed: {
    classes() {
      return {
        toggle: true,
        'toggle--active': this.isMenuOpen
      }
    }
  },
  methods: {
    handle() {
      this.$emit('toggleMenu')
    }
  }
}
</script>

<style scoped lang="sass">
.toggle
  +media((position: (0: fixed, 1024: relative)))
  background-color: transparent
  border: none
  cursor: pointer
  display: flex
  padding: 0
  width: rem(32)
  height: rem(32)
  margin-left: auto
  z-index: 4
  position: absolute
  right: rem(20)
  top: rem(20)
  svg
    width: 100%
    height: auto
  .line
    fill: none
    stroke: $black
    stroke-width: 10
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1)
    &--1, &--3
      stroke-dasharray: 60 207
    &--2
      stroke-dasharray: 60 60
  &--active
    .line
      stroke: $white
      &--1, &--3
        stroke-dasharray: 90 207
        stroke-dashoffset: -134
      &--2
        stroke-dasharray: 1 60
        stroke-dashoffset: -30
</style>
