<template>
  <section v-if="currentService">
    <service-page-introduction :service="currentService" />
    <service-page-about :service="currentService" />
    <service-page-description :service="currentService" />
    <other-services :services="otherServices" />
    <app-estimation />
  </section>
</template>

<script>
import { services } from '@/utils/services'
import OtherServices from '@/components/dump/Services/OtherServices'
import ServicePageIntroduction from '@/components/dump/ServicePage/ServicePageIntroduction'
import ServicePageAbout from '@/components/dump/ServicePage/ServicePageAbout'
import ServicePageDescription from '@/components/dump/ServicePage/ServicePageDescription'
import AppEstimation from '@/components/dump/AppEstimation'

export default {
  name: 'ServicesPage',
  components: {
    AppEstimation,
    ServicePageDescription,
    ServicePageAbout,
    ServicePageIntroduction,
    OtherServices
  },
  computed: {
    currentServicePath() {
      return this.$route.params.serviceName
    },
    currentService() {
      return services.find(service => service.path === this.currentServicePath)
    },
    otherServices() {
      return services.filter(service => service.path !== this.currentServicePath)
    }
  }
}
</script>

<style scoped></style>
