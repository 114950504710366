<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CommonText',
  props: {
    isBold: Boolean,
    color: {
      type: String,
      validator: v => ['black', 'white'].includes(v),
      default: 'black'
    },
    size: {
      type: String,
      validator: v => ['sm', 'md', 'xs'].includes(v),
      default: 'md'
    }
  },

  computed: {
    classes() {
      return {
        'common-text': true,
        [`common-text--${this.color}`]: this.color,
        [`common-text--${this.size}`]: this.size,
        'common-text--bold': this.isBold
      }
    }
  }
}
</script>

<style scoped lang="sass">
.common-text
  text-align: left
  font-style: normal
  font-weight: 300
  line-height: 140%
  a
    text-decoration: none
    color: inherit
    @media(any-hover: hover)
      &:hover
        text-decoration: underline

  &--xs
    font-size: rem(14)

  &--sm
    font-size: rem(15)

  &--md
    font-size: rem(18)

  &--black
    color: $black

  &--white
    color: $white

  &--contacts
      width: 53%
      @media screen and (max-width: 767px)
        width: 100%
</style>
