import axios from 'axios'

const apiUrl = '/api'

export const sendDataToEmail = formData => {
  const baseUrl = `${apiUrl}/send`
  return axios.post(baseUrl, formData)
}

export const getPaypalSig = formData => {
  const baseUrl = `${apiUrl}/payssionsig`
  return axios.post(baseUrl, formData)
}
