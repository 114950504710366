<template>
  <section class="partnership">
    <div class="partnership__top">
      <div class="partnership__top-content">
        <app-breadcrumbs :breadcrumbs="breadcrumbs" />
        <common-title class="partnership__title" disable-margin>
          Grow Your Business <br />
          With Gipnetix
        </common-title>
      </div>
    </div>
    <app-payment />
    <app-estimation :show-sub-title="false"> Share your feedback<br />and thoughts </app-estimation>
    <other-services :services="services">
      <template #title> Our Services </template>
    </other-services>
  </section>
</template>

<script>
import { services } from '@/utils/services'
import CommonTitle from '@/components/ui/CommonTitle'
import AppEstimation from '@/components/dump/AppEstimation'
import AppBreadcrumbs from '@/components/dump/AppBreadcrumbs'
import OtherServices from '@/components/dump/Services/OtherServices'
import AppPayment from '@/components/ui/AppPayment'

export default {
  name: 'PartnershipPage',
  components: {
    AppBreadcrumbs,
    AppEstimation,
    AppPayment,
    CommonTitle,
    OtherServices
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        link: '/'
      },
      {
        text: 'Partnership',
        link: '/partnership'
      }
    ],
    services: services
  }),
  computed: {}
}
</script>

<style scoped lang="sass">
.partnership
  &__top
    display: grid
    grid-template-columns: 1fr 6fr 1fr
    margin: rem(160) 0 0

    @media (max-width: 768px)
      margin: rem(60) 0 0
      grid-template-columns: $mobile-grid

    &-content
      grid-column-start: 2

  &__title
    margin: rem(80) 0 rem(140)

    @media (max-width: 768px)
      margin: rem(40) 0 rem(50)

.other-services
  margin-bottom: 0
</style>
