<template>
  <div class="other-services">
    <common-title class="other-services__title" auto-side-margin full-width>
      <slot name="title"> Other Services </slot>
    </common-title>
    <div class="other-services__items">
      <app-router-link v-for="service in services" :key="service.path" :to="service.path">
        <services-list-item :service="service" minified />
      </app-router-link>
    </div>
  </div>
</template>

<script>
import AppRouterLink from '@/components/HOCS/AppRouterLink'
import ServicesListItem from '@/components/dump/Services/ServicesListItem'
import CommonTitle from '@/components/ui/CommonTitle'

export default {
  name: 'OtherServices',
  components: { CommonTitle, ServicesListItem, AppRouterLink },
  props: {
    services: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="sass">
.other-services
  display: grid
  grid-template-columns: 1fr 6fr 1fr
  margin: rem(160) 0

  @media screen and (max-width: 768px)
    grid-template-columns: $mobile-grid
    margin: rem(100) 0

  &__title, &__items
    grid-column-start: 2
    max-width: rem(1208)
  &__items
    width: 100%
    margin: 0 auto

    display: grid
    grid-template-columns: 1fr 1fr
    gap: rem(40)

    @media screen and (max-width: 767px)
      grid-template-columns: 1fr
</style>
