<template>
  <aside class="app-aside">
    <app-menu />
  </aside>
</template>

<script>
import AppMenu from '@/components/dump/AppMenu'

export default {
  name: 'AppAside',
  components: { AppMenu }
}
</script>

<style scoped lang="sass">
.app-aside
  height: calc(100vh - 40px)
  min-height: rem(800)
  position: sticky
  top: rem(20)
  padding: rem(62) rem(20)
  box-sizing: border-box
</style>
