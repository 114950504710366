<template>
  <nav class="app-nav">
    <img class="app-nav__arrow" src="@/assets/img/app-nav-arrow.svg" alt="↓" />
    <app-link class="app-nav__link" href="/#meet"> Meet Gipnetix </app-link>
    <div
      class="app-nav__toggle"
      :class="{ 'app-nav__toggle--active': showDropdown }"
      @click="toggleDropdown"
    >
      Our services
      <img src="@/assets/img/chevron-down.svg" class="chevron" alt="↓" />
    </div>
    <ul v-if="showDropdown" class="app-nav__dropdown">
      <li v-for="service in services" :key="service.path">
        <app-router-link class="app-nav__link" :to="`/services/${service.path}`">
          {{ service.name }}
        </app-router-link>
      </li>
    </ul>
    <app-router-link class="app-nav__link" to="/contacts"> contacts </app-router-link>
  </nav>
</template>

<script>
import AppLink from '@/components/ui/AppLink'
import AppRouterLink from '@/components/HOCS/AppRouterLink'
import { services } from '@/utils/services'

export default {
  name: 'AppNav',
  components: { AppRouterLink, AppLink },
  data: () => ({
    showDropdown: false
  }),
  computed: {
    services: () => services
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    }
  }
}
</script>

<style scoped lang="sass">
.app-nav
  display: flex
  flex-direction: column
  gap: rem(20)
  align-items: center
  justify-content: center
  &__link, &__toggle
    font-style: normal
    font-weight: 300
    font-size: rem(16)
    line-height: 175%
    text-align: center
    text-transform: uppercase
    color: #BBBBBB
    text-decoration: none
    @media(any-hover: hover)
      &:hover
        color: $white

  &__toggle
    transition: .3s
    display: inline-flex
    align-items: center
    gap: rem(4)
    cursor: pointer
    &--active
      .chevron
        transform: rotate(180deg)
  &__dropdown
    margin: 0
    list-style: none
    text-align: center
    padding: 0
    gap: rem(20)
    display: grid
  &__arrow
    margin-bottom: rem(28)
</style>
