<template>
  <section class="spd-description">
    <div class="spd-description__content">
      <div class="spd-description__content-left">
        <common-text class="text" v-html="service.descriptionSecond"></common-text>
      </div>
      <div class="spd-description__content-right">
        <white-frame class="frame">
          <app-logo color="black" />
          <common-text>
            {{ service.estimateText }}
          </common-text>
          <app-link href="#submit" :type="linkTypes.BTN_BLACK">
            Estimate Your Project
            <template #append>
              <img src="@/assets/img/arrow-white-right.svg" alt="→" />
            </template>
          </app-link>
        </white-frame>
      </div>
    </div>
  </section>
</template>

<script>
import CommonText from '@/components/ui/CommonText'
import WhiteFrame from '@/components/ui/WhiteFrame'
import AppLogo from '@/components/ui/AppLogo'
import AppLink from '@/components/ui/AppLink'
import { LINK_TYPES } from '@/utils/link-types'

export default {
  name: 'ServicePageDescription',
  components: { AppLink, AppLogo, WhiteFrame, CommonText },
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    linkTypes: () => LINK_TYPES
  }
}
</script>

<style scoped lang="sass">
.spd-description
  display: grid
  grid-template-columns: 1fr 6fr 1fr
  margin-bottom: rem(160)

  @media screen and (max-width: 768px)
    grid-template-columns: $mobile-grid
    margin-bottom: rem(100)

  &__content
    grid-column-start: 2
    max-width: rem(1208)
    width: 100%
    margin: 0 auto
    gap: 10%
    display: flex
    align-items: center

    @media screen and (max-width: 1600px)
      flex-direction: column-reverse
      align-items: flex-start

    &-left
      max-width: rem(470)
      .text
        padding-left: 30px
        position: relative

        &::before
          content: ''
          position: absolute
          left: 0
          top: 0
          width: 1px
          height: 100%
          background-color: #000

        @media screen and (max-width: 1600px)
          padding-left: 15px
          margin-top: rem(40)

    &-right
      max-width: 35vw
      min-width: 390px

      @media screen and (max-width: 1600px)
        max-width: 750px
        min-width: auto
        width: 100%
      .frame
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: rem(30)
</style>
