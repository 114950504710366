import Vue from 'vue'
import Router from 'vue-router'

import HomePage from '@/views/HomePage'
import MissingPage from '@/views/MissingPage'
import ContactsPage from '@/views/ContactsPage'
import { routeNames } from '@/utils/RouteNames'
import ServicePage from '@/views/ServicePage'
import PartnershipPage from '@/views/PartnershipPage'
import { siteName } from '@/utils'
import { services } from '@/utils/services'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: routeNames.home,
      component: HomePage,
      meta: {
        title: siteName
      }
    },
    {
      path: '/services',
      redirect: '/'
    },
    {
      path: '/services/:serviceName',
      name: routeNames.service,
      component: ServicePage,
      meta: {
        title: siteName
      }
    },
    {
      path: '/contacts',
      name: routeNames.contacts,
      component: ContactsPage,
      meta: {
        title: siteName
      }
    },
    {
      path: '/partnership',
      name: routeNames.partnership,
      component: PartnershipPage,
      meta: {
        title: siteName
      }
    },
    {
      path: '/*',
      name: routeNames.missingPage,
      component: MissingPage,
      meta: {
        title: `${routeNames.missingPage}`
      }
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name === routeNames.service) {
    const { params } = to
    const { serviceName } = params
    const isServiceExist = services.map(service => service.path).includes(serviceName)
    isServiceExist ? next() : next('/404')
  } else {
    next()
  }
})

export default router
