<template>
  <section id="meet" class="hpm-meet">
    <common-title class="hpm-meet__title"> Meet Gipnetix </common-title>
    <div class="hpm-meet__content">
      <div class="hpm-meet__content-left">
        <common-text size="sm">
          Knowledge makes our projects successful: the backbone in digital marketing is a
          combination of cooperation, experience and creative process. We value our highly
          intelligent and motivated team, which thrives in fast growth and learning.
        </common-text>
        <ul class="list">
          <li><b>Empowering</b> your brand potential</li>
          <li><b>Creating</b> a thriving marketing foundation</li>
          <li><b>Forming</b> strong relationships with your users and business purpose</li>
          <li><b>Developing</b> a creative vision</li>
        </ul>

        <common-text size="sm">
          Gipnetix has the required brand and marketing expertise, which helps our professionals
          with vivid creative minds to build the custom marketing models for your business.
          <b>We are ready to face challenges and solve tough issues.</b>
        </common-text>

        <app-router-link to="/contacts" type="btn-ghost-black" class="link">
          Open contacts
          <template #append>
            <img src="@/assets/img/arrow-black-right.svg" alt="→" />
          </template>
        </app-router-link>
      </div>
      <div class="hpm-meet__content-right">
        <white-frame dots-position="bottom">
          <common-text>
            <b>
              Gipnetix is a Singapore-based marketing company that will design, develop and
              transform your brand image into the most powerful asset.
            </b>
            <br />
            <br />
            As your marketing requires improvement, you also need to act fast and our work helps to
            do that.
          </common-text>
        </white-frame>
      </div>
    </div>
  </section>
</template>

<script>
import CommonTitle from '@/components/ui/CommonTitle'
import CommonText from '@/components/ui/CommonText'
import WhiteFrame from '@/components/ui/WhiteFrame'
import AppRouterLink from '@/components/HOCS/AppRouterLink'

export default {
  name: 'HomePageMeet',
  components: { AppRouterLink, WhiteFrame, CommonText, CommonTitle }
}
</script>

<style scoped lang="sass">
.hpm-meet
  display: grid
  +media((grid-template-columns: (0: $mobile-grid, 768: 1fr 6fr 1fr)))
  &__content, &__title
    grid-column-start: 2

  &__content
    display: flex
    align-items: flex-start
    justify-content: space-between
    +media((flex-direction: (0: column, 768: row)))
    +media((gap: (0: rem(40), 768: 15%)))
    &-right
      flex: 0 0 auto
      +media((width: (768: rem(460))))

      @media screen and (min-width: 768px)
        width: 65%

      +media((width: (1600: rem(460))))

.list
  text-align: left
  font-style: normal
  font-weight: 300
  line-height: 140%
  font-size: rem(15)
  margin: rem(15) 0 rem(30)
  padding-left: rem(30)

.link
  +media((margin-top: (0: rem(32), 768: rem(60))))
</style>
