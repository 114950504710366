<template>
  <router-link v-bind="$attrs" :class="classes">
    <slot />
    <span v-if="$slots.append" class="router-link__append">
      <slot name="append" />
    </span>
  </router-link>
</template>

<script>
import { LINK_TYPES } from '@/utils/link-types'

export default {
  name: 'AppRouterLink',
  props: {
    isUnderline: Boolean,
    type: {
      type: String,
      validator: v => Object.values(LINK_TYPES).includes(v),
      default: 'default'
    }
  },
  computed: {
    classes() {
      return {
        'router-link': true,
        [`router-link--${this.type}`]: this.type,
        'router-link--underline': this.isUnderline
      }
    }
  }
}
</script>

<style scoped lang="sass">
.router-link
  transition: .3s
  display: inline-flex
  gap: rem(14)
  text-decoration: none

  &--underline
    text-decoration: underline

  &--btn-ghost-white, &--btn-black, &--btn-ghost-black
    @extend %link-like-btn

  &--btn-ghost-white
    @extend %btn-ghost-white

  &--btn-ghost-black
    @extend %btn-ghost-black

  &--btn-black
    @extend %btn-black
</style>
