<template>
  <div class="home-page">
    <home-page-introduction />
    <home-page-meet />
    <services-list />
    <app-estimation />
  </div>
</template>

<script>
import HomePageIntroduction from '@/components/dump/Home/HomePageIntroduction'
import ServicesList from '@/components/dump/Services/ServicesList'
import HomePageMeet from '@/components/dump/Home/HomePageMeet'
import AppEstimation from '@/components/dump/AppEstimation'

export default {
  name: 'HomePage',
  components: { AppEstimation, HomePageMeet, ServicesList, HomePageIntroduction },
  data: () => ({
    isCounterShow: false
  }),
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  }
}
</script>

<style scoped lang="sass"></style>
