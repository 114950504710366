<template>
  <button class="app-button" @click="handle">
    <span
      v-if="$slots.default"
      class="app-button__content"
      :class="{ 'app-button__content--blur': isLoading }"
    >
      <slot />
    </span>
    <span
      v-if="$slots.append"
      class="app-button__append"
      :class="{ 'app-button__append--blur': isLoading }"
    >
      <slot name="append" />
    </span>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#fff"
      :height="30"
      :width="30"
      :opacity="1"
      background-color="transparent"
      :z-index="3"
      :is-full-page="false"
      blur="0px"
    />
  </button>
</template>

<script>
import loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'AppButton',
  components: {
    loading
  },
  props: {
    isLoading: {
      type: Boolean
    }
  },
  methods: {
    handle() {
      if (!this.isLoading) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="sass">
.app-button
  cursor: pointer
  @extend %link-like-btn
  @extend %btn-black
  border: none
  box-shadow: none
  outline: none
  transition: .3s
  display: inline-flex
  gap: rem(14)
  align-items: center
  position: relative
  overflow: hidden
  .vld-overlay
    line-height: 0

  &__append, &__content
    &--blur
      filter: blur(rem(2))
</style>
